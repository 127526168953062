import "./App.css";
import FakeLoadingWrapper from "./Components/FakeLoadingWrapper";
import Navigation from "./Components/Navigation";
import NotFound from "./Components/Pages/NotFound";
import Login from "./Components/Pages/Login";
import Home from "./Components/Pages/Home";
import Operations from "./Components/Pages/Operations";
import OperationDetail from "./Components/Pages/OperationDetail";
import RequireLogin from "./Components/RequireLogin";
import Entities from "./Components/Pages/Entities";
// import AudioProvider from './Components/AudioProvider';
// import ComputerNoises from './Components/ComputerNoises';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginProvider from "./Components/LoginProvider";
import EntityDetail from "./Components/Pages/EntityDetail";
import Locations from "./Components/Pages/Locations";
import LocationDetail from "./Components/Pages/LocationDetail";
import Admin from "./Components/Pages/Admin";

function App() {
  //

  return (
    <div className="crt">
      <Router>
        {/* TODO Navigation */}
        <LoginProvider>
          <Navigation />
            <Routes>
              <Route
                path="/login"
                element={
                  <FakeLoadingWrapper>
                    <Login />
                  </FakeLoadingWrapper>
                }
              />
              <Route
                path="/"
                element={
                  <RequireLogin>
                    <FakeLoadingWrapper>
                      <Home />
                    </FakeLoadingWrapper>
                  </RequireLogin>
                }
              />
              <Route
                path="/Operations"
                element={
                  <RequireLogin>
                    <FakeLoadingWrapper>
                      <Operations />
                    </FakeLoadingWrapper>
                  </RequireLogin>
                }
              />
              <Route
                path="/Operation/:id"
                element={
                  <RequireLogin>
                    <FakeLoadingWrapper>
                      <OperationDetail />
                    </FakeLoadingWrapper>
                  </RequireLogin>
                }
              />
              <Route
                path="/Entities"
                element={
                  <RequireLogin>
                    <FakeLoadingWrapper>
                      <Entities />
                    </FakeLoadingWrapper>
                  </RequireLogin>
                }
              />
              <Route
                path="/Entity/:id"
                element={
                  <RequireLogin>
                    <FakeLoadingWrapper>
                      <EntityDetail />
                    </FakeLoadingWrapper>
                  </RequireLogin>
                }
              />
              <Route
                path="/Locations"
                element={
                  <RequireLogin>
                    <FakeLoadingWrapper>
                      <Locations />
                    </FakeLoadingWrapper>
                  </RequireLogin>
                }
              />
              <Route
                path="/Location/:id"
                element={
                  <RequireLogin>
                    <FakeLoadingWrapper>
                      <LocationDetail />
                    </FakeLoadingWrapper>
                  </RequireLogin>
                }
              />
              <Route
                path="/Admin"
                element={
                  <RequireLogin>
                    <FakeLoadingWrapper>
                      <Admin />
                    </FakeLoadingWrapper>
                  </RequireLogin>
                }
              />
              <Route path="*" element={<FakeLoadingWrapper>
                      <NotFound />
                    </FakeLoadingWrapper>} />
            </Routes>
        </LoginProvider>
      </Router>
    </div>
  );
}

export default App;
