import { useEffect, useState, useMemo } from 'react';

const FakeLoadingWrapper = ({children}) => {
    const [ticking, setTicking] = useState(true),
        [count, setCount] = useState(0),
        [loadTime] = useState(Math.floor(Math.random() * (5 - 2 + 1)) + 2)

    const [loadingTextTicking, setLoadingTextTicking] = useState(true),
        [loadingTextCount, setLoadingTextCount] = useState(0)
   
   useEffect(() => {
    const timer = setTimeout(() => {
        ticking && setCount(count+1)
    }, 1e3)
    if(count >= loadTime){
        setTicking(false)
        clearTimeout(timer)
    }
    return () => clearTimeout(timer)
   }, [count, loadTime, ticking])

   useEffect(() => {
    const timer = setTimeout(() => {
        loadingTextTicking && setLoadingTextCount(loadingTextCount+1)
    }, 400)
    if(count >= loadTime){
        setLoadingTextTicking(false)
        clearTimeout(timer)
    }
    return () => clearTimeout(timer)
   }, [count, loadTime, loadingTextCount, loadingTextTicking, ticking])

   const loadingBarString = useMemo(() => {
    const loadedPercent = (count / loadTime) * 100;
    let returnString = ""
    for(let i = 0; i < loadedPercent / 10; i++){
        returnString += "| "
    }

    return returnString;
   }, [count, loadTime])

   const loadingTextDots = useMemo(() => {
    if(loadingTextCount > 3){
        setLoadingTextCount(0)
    }else{
        let returnString = ""
        for(let i = 0; i < loadingTextCount; i++){
            returnString += "."
        }

    return returnString;
    }
   }, [loadingTextCount])
   
   return (
    ticking ?  <div style={{width:"200px", padding: "10px", border: "1px dotted", display: "flex", textAlign: "left", flexDirection: "column", margin: "auto", marginTop: "45vh"}}>
    <div style={{margin: "auto", textAlign: "left"}}>{`Loading${loadingTextDots}`}</div>
    <div style={{margin: "auto", textAlign: "left"}}>{loadingBarString}</div>
  </div> : children
   )
}

export default FakeLoadingWrapper;