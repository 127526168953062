import {useState, useMemo} from "react";
import { useNavigate } from "react-router-dom";
import locations from "../../data/Locations/locations.json";
import redactText from "../../utils/redactText";

const Locations = () => {
    const [search, setSearch] = useState("");

    const navigate = useNavigate();

    const searchResults = useMemo(() => {
        if(!search || search.length === 0){
            return locations;
        }
        const lowerSearch = search.split(" ").join("").replace("/r", "").replace("r/", "").replace(" ", "").toLowerCase().replace("redacted", "");
        return locations.filter(location => (location.city.toLowerCase().includes(lowerSearch) || location.state.toLowerCase().includes(lowerSearch) || location.country.toLowerCase().includes(lowerSearch)))
    }, [search])
    return (
        <div style={{margin: "25px", marginTop: "65px", border: "1px dashed", padding: "15px"}}>
            <div style={{margin: "auto", width: "fit-content"}}>
                <h3 style={{textAlign: "center"}}>Search</h3>
                <input value={search} onChange={(e) => setSearch(e.target.value)} />
            </div>
            <div style={{display: "flex", flexFlow:"row wrap", justifyContent: "center", marginTop: "20px"}}>
                    {searchResults.map(location => (
                        <div onClick={() => navigate(`/Location/${location.id}`, {reloadDocument: true})} className="card" key={location.id} style={{cursor: "pointer", display: "flex", padding: "10px", paddingLeft: "25px", paddingRight: "25px", border: "dashed 1px", height: "fit-content"}}>
                            <h3>{redactText(location.city)}</h3>
                            <p>{redactText(location.state)}</p>
                            <p>{redactText(location.country)}</p>
                        </div>
                    ))}
                </div>
        </div>
    )
}

export default Locations