import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import people from "../../data/People/people.json";
import { useNavigate } from 'react-router-dom';
import redactText from '../../utils/redactText';
import fillEntityData from '../../utils/fillEntityData';

const EntityDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const entities = useMemo(() => people, []);

    const entity = useMemo(() => {
        debugger;
        const e = entities.find(entity => entity.id === +id);
        if(e){
            const entityData = fillEntityData(e);
            return entityData;
        }

        return undefined;
    }, [entities, id]);

    useEffect(() => {
        if(!entity){
            navigate("/Entities") 
        }
    }, [entity, navigate])

    return entity ? (
        <div style={{margin: "25px", marginTop: "65px", border: "1px dashed", padding: "15px"}}>
            <div>
                <h3>{redactText(entity.name)}</h3>
                <p>{redactText(entity.occupation)}</p>
                <p>{redactText(entity.status)}</p>
                <p>{redactText(entity.type)}</p>
            </div>
            <div style={{display: "flex"}}>
                <div style={{margin: "auto", width: "40%", border: "1px dashed", padding: "25px"}}>
                    <h3>Bio</h3>
                    <p>{entity.bio}</p>
                </div>
                <div style={{width: "40%", border: "1px dashed", padding: "25px", height: "fit-content"}}>
                    {entity?.classification ? 
                    <>
                        <h3>Classification</h3>
                        {typeof entity.classification !== "string" ? (
                            <ul>
                                {entity.classification.map(classification => (
                                    <li key={classification}>{redactText(`/r${classification}r/`)}</li>
                                ))}
                            </ul>
                        ) : (
                            <p>{redactText(`/r${entity.classification}r/`)}</p>
                        )}
                    </>
                    : null}
                    <h3>Associated Operations</h3>
                    <ul>
                        {entity.associatedOperations.map(operation => {
                        return (
                            <li key={operation.id}>{operation?.location?.city + " - " + operation?.year}</li>
                        )})}
                    </ul>
                    {entity?.activityWitnessed ? 
                    <>
                        <h3>Activity Witnessed</h3>
                        <p>{redactText(entity.activityWitnessed)}</p>
                        
                    </>
                    : null}
                </div>
            </div>
            {
                entity?.playerCharacteristics ? 
                (
                    <>
                        <div style={{display: "flex", justifyContent: "space-around", flexFlow: "row wrap"}}>
                            <h3>{`HP: ${entity.playerCharacteristics.HP}`}</h3>
                            <h3>{`AC: ${entity.playerCharacteristics.AC}`}</h3>
                            <h3>{`Speed: ${entity.playerCharacteristics.Speed}`}</h3>
                            <h3>{`Initiative: ${entity.playerCharacteristics.Initiative}`}</h3>
                            <h3>{`Proficiency: ${entity.playerCharacteristics.ProficiencyBonus}`}</h3>
                            <h3>{`Load: ${entity.playerCharacteristics.equipmentSlots}`}</h3>
                            <h3>{`Spellcasting Ability: ${entity.playerCharacteristics.spellcastingAbility}`}</h3>
                            <h3>{`Spell Save: ${entity.playerCharacteristics.spellSaveDC}`}</h3>
                            <h3>{`Spell Attack: ${entity.playerCharacteristics.spellAttackBonus}`}</h3>
                        </div>
                        <div style={{display: "flex", justifyContent: "center"}}>
                                <div style={{border: "1px dashed #FFEFB6", padding: "25px"}}>
                                    <h3>Skills</h3>
                                    <ul>
                                        <li>
                                            <span>STR:</span>
                                            <span>{entity.playerCharacteristics.STR}</span>
                                            <span>{`, +${entity.playerCharacteristics.STRMOD}`}</span>
                                        </li>
                                        <li>
                                            <span>DEX:</span>
                                            <span>{entity.playerCharacteristics.DEX}</span>
                                            <span>{`, +${entity.playerCharacteristics.DEXMOD}`}</span>
                                        </li>
                                        <li>
                                            <span>CON:</span>
                                            <span>{entity.playerCharacteristics.CON}</span>
                                            <span>{`, +${entity.playerCharacteristics.CONMOD}`}</span>
                                        </li>
                                        <li>
                                            <span>INT:</span>
                                            <span>{entity.playerCharacteristics.INT}</span>
                                            <span>{`, +${entity.playerCharacteristics.INTMOD}`}</span>
                                        </li>
                                        <li>
                                            <span>WIS:</span>
                                            <span>{entity.playerCharacteristics.WIS}</span>
                                            <span>{`, +${entity.playerCharacteristics.WISMOD}`}</span>
                                        </li>
                                        <li>
                                            <span>CHA:</span>
                                            <span>{entity.playerCharacteristics.CHA}</span>
                                            <span>{`, +${entity.playerCharacteristics.CHAMOD}`}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div style={{border: "1px dashed #FFEFB6", padding: "25px"}}>
                                    <h3>Saving Throws</h3>
                                    <ul>
                                        {Object.keys(entity.playerCharacteristics.SavingThrows).map(key => (
                                            <li key={key}>
                                                <span>{`${key}: `}</span>
                                                <span>{entity.playerCharacteristics.SavingThrows[key]}</span>
                                                {entity.playerCharacteristics.AdvantageThrows.includes(key) ? <span>{`+${entity.playerCharacteristics.ProficiencyBonus}`}</span> : null}
                                            </li>  
                                        ))}
                                    </ul>

                                </div>
                                <div style={{border: "1px dashed #FFEFB6", padding: "25px"}}>
                                    <h3>Proficiencies</h3>
                                    <ul>
                                        {Object.keys(entity.playerCharacteristics.proficientSkills).map(key => (
                                            <li key={key}>
                                                <span>{`${key}: `}</span>
                                                <span>{"+" + entity.playerCharacteristics.proficientSkills[key]}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            
                            <div style={{display: "flex", justifyContent: "center", flexFlow: "row wrap"}}>
                                <div style={{border: "1px dashed #FFEFB6", padding: "25px", width: "45%"}}>
                                    <h3>Equipment</h3>
                                    <ul>
                                        {entity.playerCharacteristics.equipment.weapons.map(weapon => (
                                            <>
                                                <li style={{border: "1px dashed #FFEFB6", padding: "25px"}} key={weapon.name}>
                                                    <span>{weapon.name}</span>
                                                    <div style={{display: "flex", flexFlow: "row wrap", justifyContent: "space-between"}}>
                                                        <span>
                                                        {`Load: ${weapon.slots}`}
                                                        </span>
                                                        <span>
                                                            {`Hit: ${weapon.hit}`}
                                                        </span>
                                                        <span>
                                                            {`Damage: ${weapon.damage}`}
                                                        </span>
                                                    </div>
                                                    <span>
                                                        {weapon?.specialAbilities?.length > 0 ? <ul>
                                                            {weapon?.specialAbilities.map(ability => (
                                                                <li key={ability}>
                                                                    <p>{ability}</p>
                                                                </li>
                                                            ))}
                                                        </ul> : null}
                                                    </span>
                                                    <div style={{display: "flex", flexFlow: "row wrap", justifyContent: "space-between"}}>
                                                        {weapon.properties.map(property => (
                                                            <span key={property}>{property}</span>
                                                        ))}
                                                    </div>
                                                </li>
                                            </>
                                        ))}
                                        {entity.playerCharacteristics.equipment.items.map(item => (
                                            <>
                                                <li style={{border: "1px dashed #FFEFB6", padding: "25px"}} key={item.name}>
                                                    <span>{item.name}</span>
                                                    <div style={{display: "flex", flexFlow: "row wrap", justifyContent: "space-between"}}>
                                                        <span>
                                                        {`Load: ${item.slots}`}
                                                        </span>
                                                    </div>
                                                    <span>
                                                        {item?.specialAbilities?.length > 0 ? <ul>
                                                            {item?.specialAbilities.map(ability => (
                                                                <li key={ability}>
                                                                    <p>{ability}</p>
                                                                </li>
                                                            ))}
                                                        </ul> : null}
                                                    </span>
                                                </li>
                                            </>
                                        ))}
                                    </ul>
                                </div>
                                <div style={{border: "1px dashed #FFEFB6", padding: "25px", width: "45%"}}>
                                    <h3>Actions</h3>
                                    <ul>
                                        {entity.playerCharacteristics.actions.map(action => {
                                            const usageString = action.uses > 0 ? `Uses: ${action.uses} per ${action.recharge}` : "unlimited"
                                            action = {...action, usageString}
                                            return (
                                            <>
                                                <li style={{border: "1px dashed #FFEFB6", padding: "25px"}} key={action.effect}>
                                                    <div style={{display: "flex", flexFlow: "row wrap", justifyContent: "space-between"}}>
                                                        <span>
                                                        {`${usageString}`}
                                                        </span>
                                                        <span>
                                                            {`${action.cost}`}
                                                        </span>
                                                        <span>
                                                            {action.effect}
                                                        </span>
                                                    </div>
                                                </li>
                                            </>
                                        )})}
                                    </ul>
                                </div>
                                {
                                    entity.playerCharacteristics.passiveAbilities ? (
                                    <div style={{border: "1px dashed #FFEFB6", padding: "25px", width: "45%"}}>
                                        <h3>Passives</h3>
                                            <ul>
                                                {entity.playerCharacteristics.passiveAbilities.map(passive => {
                                                    return (
                                                    <>
                                                        <li style={{border: "1px dashed #FFEFB6", padding: "25px"}} key={passive}>
                                                            <div >{passive}</div>
                                                        </li>
                                                    </>
                                                )})}
                                            </ul>
                                    </div>
                                    ) : null
                                }
                                {
                                    entity.playerCharacteristics.spells ? (
                                    <div style={{border: "1px dashed #FFEFB6", padding: "25px", width: "45%"}}>
                                        <h3>Spells</h3>
                                            <ul>
                                                {entity.playerCharacteristics.spells.map(spell => {
                                                    return (
                                                    <>
                                                        <li style={{border: "1px dashed #FFEFB6", padding: "25px"}} key={spell.name}>
                                                            <div >{spell.name}</div>
                                                            <div >{`Level: ${spell.level}`}</div>
                                                        </li>
                                                    </>
                                                )})}
                                            </ul>
                                    </div>
                                    ) : null
                                }
                                {
                                    entity.playerCharacteristics.maneuvers ? (
                                    <div style={{border: "1px dashed #FFEFB6", padding: "25px", width: "45%"}}>
                                        <h3>Maneuvers</h3>
                                            <ul>
                                                {entity.playerCharacteristics.maneuvers.map(maneuver => {
                                                    return (
                                                    <>
                                                        <li style={{border: "1px dashed #FFEFB6", padding: "25px"}} key={maneuver.name}>
                                                            <div >{maneuver.name}</div>
                                                            <div >{`Level: ${maneuver.level}`}</div>
                                                            <div>{maneuver.description}</div>
                                                        </li>
                                                    </>
                                                )})}
                                            </ul>
                                    </div>
                                    ) : null
                                }
                            </div>
                    </>
                )
                : null
            }
        </ div>
    ) : null
}

export default EntityDetail;