import pointsOfInterest from "../data/Locations/pointsOfInterest.json"
import redactText from "./redactText";

const fillLocationData = (location) => {
    location.pointsOfInterest = location.pointsOfInterest.map(poi => pointsOfInterest.find(p => p.id === poi) || poi);
    location.pointsOfInterest = location.pointsOfInterest.map(poi => {
        poi.name = redactText(poi.name)
        poi.description = redactText(poi.description)
        return poi;
    })

    return location;
}

export default fillLocationData;