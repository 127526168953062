const regex = /(?<=(\/r))(.*?)(?=(r\/))/ig
const redactText = (text = "") => {
    
    const replacementChar = "█"

    const replacementString = replacementChar + replacementChar + replacementChar + replacementChar + replacementChar

    return text.replaceAll(regex, replacementString).replaceAll("/r", "").replaceAll("r/", "")
}

export default redactText