import jackalLogo from "../../public/jackal-logo-light.png";

const Admin = () => {

    return (
        <div style={{margin: "auto", marginTop: "20vh", height: "50vh", width: "fit-content", display: "flex", flexDirection: "column"}}>
            <img className="logo" style={{ marginLeft: "auto", marginRight: "auto", width: "300px", marginTop: "25px"}} src={jackalLogo} alt=""/>
            <div style={{display: "flex", flexDirection: "column", outline: "1px dashed", padding: "15px", marginTop: "25px", justifyContent: "center"}}>
                <h3 style={{textAlign: "center"}}>Not Allowed.</h3>
                <h5 style={{textAlign: "center"}}>This attempt to access a forbidden page has been logged.</h5>
            </div>
        </div>
    )
}

export default Admin;