import useLogin from "../Hooks/useLogin"
import {useNavigate, useLocation} from "react-router-dom"
import { useEffect } from "react";

const RequireLogin = ({children}) => {
    const {login = undefined} = useLogin()
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if(!login){
            navigate(`/login?page=${location.pathname}`)
        }
    }, [location, login, navigate])

    if(!login){
        return null;
    }

    return children
}

export default RequireLogin