import { Link, useLocation } from 'react-router-dom'
import useLogin from '../Hooks/useLogin'
import jackalAsciiLogo from "../public/jackal-logo-ascii-small.png"

const Navigation = () => {
    const { login } = useLogin();
    const location = useLocation();
    return (
        login ? <nav>
            <ul style={{display: "flex", justifyContent: "start"}}>
                <li><img style={{width: "40px", marginRight: "15px"}} src={jackalAsciiLogo} alt="Jackal Logo" /></li>
                <li><Link reloadDocument to="/" className={location.pathname === "/" ? "selected" : undefined}>Home</Link></li>
                <li><Link reloadDocument to="/Operations" className={location.pathname === "/Operations" ? "selected" : undefined}>Operations</Link></li>
                <li><Link reloadDocument to="/Entities" className={location.pathname === "/Entities" ? "selected" : undefined}>Entities</Link></li>
                <li><Link reloadDocument to="/Locations" className={location.pathname === "/Locations" ? "selected" : undefined}>Locations</Link></li>
                {login && <li><Link to="/Admin" className={location.pathname === "/Admin" ? "selected" : undefined}>Admin</Link></li>}
            </ul>
        </nav> : null
    )
}

export default Navigation;