import useLogin from "../../Hooks/useLogin";
import news from "../../data/news";
import { useMemo } from "react";

const Home = () => {
    const {login} = useLogin();
    const myNews = useMemo(() => news.filter(newsItem => newsItem.audience <= login.permissions || (newsItem?.direct_to_user && newsItem.direct_to_user === login.id )).sort((newsA, newsB) => {
        const dateA = new Date(newsA.date);
        const dateB = new Date(newsB.date);
        return dateB - dateA;
    }), [login.id, login.permissions])
    
    return (
        <>
            <div style={{margin: "25px", marginTop: "65px", border: "1px dashed", padding: "15px"}}>
                <h1>Jackal and Co. Internal Records System</h1>
                <h4>{`Welcome, ${login?.name}!`}</h4>
                <article>
                    <p>Use of this system and the data contained within is to be restricted to authorized employees, contractors, and operatives of Jackal and Co.</p>
                    <p>Unauthorized use of this system is prohibited and may be subject to criminal and civil penalties.</p>
                    <p>By continuing to use this system, you acknowledge your awareness of and concurrence with the preceding statements.</p>
                    <p>&copy; 1992 Jackal and Co.</p>
                </article>
            </div>
            <div style={{margin: "25px", border: "1px dashed", padding: "15px"}}>
                <h1>News</h1>
                <div style={{display: "flex", flexWrap: "wrap"}}>
                    {myNews.map((newsItem) => (
                        <article style={{width: "45%", marginRight: "5%"}} key={newsItem.id}>
                        <h3>{newsItem.title}</h3>
                        <p>{newsItem.date}</p>
                        <p>{newsItem.content}</p>
                    </article>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Home;