import operations from "../data/PreviousEngagements";
import redactText from "./redactText";
import fillEngagementData from "./fillEngagementData";

const fillEntityData = (entity) => {
    const entityCopy = {...entity}
    const associatedOperations = operations.filter(operation => entity.associatedOperations.includes(operation.id));
    entityCopy.associatedOperations = associatedOperations.map(operation => fillEngagementData(operation));
    entityCopy.bio = redactText(entity.bio);
    return entityCopy;
}

export default fillEntityData;