import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import previousEngagements from '../../data/PreviousEngagements';
import fillEngagementData from '../../utils/fillEngagementData';
import { useNavigate } from 'react-router-dom';
import redactText from '../../utils/redactText';

const OperationDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const engagement = useMemo(() => {
        console.log({id, previousEngagements})
        const e = previousEngagements.find(engagement => engagement.id === +id);
        if(e){
            return fillEngagementData(e);
        }

        return undefined;
    }, [id]);

    useEffect(() => {
        if(!engagement){
            navigate("/Operations") 
        }
    })

    return engagement ? (
        <div style={{margin: "25px", marginTop: "65px", border: "1px dashed", padding: "15px"}}>
            <div>
                <h3>{engagement.location.city} - {engagement.location.state}</h3>
                <p>{engagement.year}</p>
            </div>
            <div style={{display: "flex"}}>
                <div style={{margin: "auto", width: "40%", border: "1px dashed", padding: "25px"}}>
                    <h3>Brief</h3>
                    <p>{engagement.mission.brief}</p>
                    <h3>Outcome</h3>
                    <p>{engagement.mission.outcome}</p>
                </div>
                <div style={{width: "40%", border: "1px dashed", padding: "25px", height: "fit-content"}}>
                    <h3>Operatives</h3>
                    <ul>
                        {engagement.operatives.map(operative => (
                            <li key={operative.id}>{redactText(operative.name)}</li>
                        ))}
                    </ul>
                    <h3>Related Entities</h3>
                    <ul>
                        {engagement.relatedEntities.map(entity => (
                            <li key={entity.id}>{redactText(entity.name)}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </ div>
    ) : null
}

export default OperationDetail;