import jackalLogo from "../../public/jackal-logo-light.png"
import { useState } from "react"
import useLogin from "../../Hooks/useLogin"

const Login = () => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const {tryLogin, loginMessage} = useLogin()
    return (
        <div style={{margin: "auto", marginTop: "20vh", height: "50vh", width: "fit-content", display: "flex", flexDirection: "column"}}>
            <img className="logo" style={{width: "300px", marginTop: "25px"}} src={jackalLogo} alt=""/>
            <div style={{display: "flex", flexDirection: "column", outline: "1px dashed", padding: "15px", marginTop: "25px"}}>
                <input className="input" style={{marginBottom: "15px"}} type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)}/>
                <input className="input" type="password" placeholder="Password" value={password} onChange={(e)=> setPassword(e.target.value)}/>
            </div>
            <button className="button" style={{marginTop: "25px", width: "50%", alignSelf: "center"}} onClick={() => tryLogin({username, password})}>Login</button>
            <div style={{textAlign: "center", marginTop: "25px"}}>{loginMessage}</div>
        </div>
    )
}

export default Login;