import jackalLogo from "../../public/jackal-logo-light.png"

const Login = () => {
    return (
        <div style={{margin: "auto", marginTop: "20vh", height: "50vh", width: "fit-content", display: "flex", flexDirection: "column"}}>
            <img className="logo" style={{width: "300px", marginTop: "25px"}} src={jackalLogo} alt=""/>
            <h3 style={{textAlign: "center"}}>PAGE NOT FOUND</h3>
        </div>
    )
}

export default Login;