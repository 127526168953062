import locations from "../data/Locations/locations.json"
import people from "../data/People/people.json"
import redactText from "./redactText";

const fillEngagementData = (engagement) => {
    engagement.location = locations.find(location => location.id === engagement.location) || engagement.location;
    engagement.operatives = engagement.operatives.map(operative => people.find(person => person.id === operative) || operative);
    engagement.relatedEntities = engagement.relatedEntities.map(relatedEntity => people.find(person => person.id === relatedEntity) || relatedEntity);
    engagement.mission.brief = redactText(engagement.mission.brief)
    engagement.mission.outcome = redactText(engagement.mission.outcome)

    return engagement;
}

export default fillEngagementData;