import {useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import people from "../../data/People/people.json"
import redactText from "../../utils/redactText";

const Entities = () => {
    const [search, setSearch] = useState("");

    const navigate = useNavigate();

    const searchResults = useMemo(() => {
        const filledEntities = people
        if(!search || search.length === 0){
            return filledEntities.filter(entity => entity.name !== "" && entity.occupation !== "");
        }
        const lowerSearch = search.split(" ").join("").replace("/r", "").replace("r/", "").replace(" ", "").toLowerCase().replace("redacted", "");
        return filledEntities.filter(entity => (entity?.name && entity.name !== "" && entity.name.toLowerCase().includes(lowerSearch)) || (entity?.occupation && entity.occupation !== "" && entity.occupation.includes(lowerSearch)))
    }, [search])
    return (
        <div style={{margin: "25px", marginTop: "65px", border: "1px dashed", padding: "15px"}}>
            <div style={{margin: "auto", width: "fit-content"}}>
                <h3 style={{textAlign: "center"}}>Search</h3>
                <input value={search} onChange={(e) => setSearch(e.target.value)} />
            </div>
            <div style={{display: "flex", flexFlow:"row wrap", justifyContent: "center", marginTop: "20px"}}>
                    {searchResults.map(entity => (
                        <div onClick={() => navigate(`/Entity/${entity.id}`, {reloadDocument: true})} className="card" key={entity.id} style={{cursor: "pointer", display: "flex", padding: "10px", paddingLeft: "25px", paddingRight: "25px", border: "dashed 1px", height: "fit-content"}}>
                            <h3>{redactText(entity.name)}</h3>
                            <p>{redactText(entity.occupation)}</p>
                            <p>{redactText(entity.status)}</p>
                        </div>
                    ))}
                </div>
        </div>
    )
}

export default Entities;