import logins from "../data/Logins"
import { useCallback, useState, createContext, useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

export const LoginContext = createContext()

const LoginProvider = ({ children }) => {
    const [login, setLogin] = useState("" + window.sessionStorage.getItem("login") !== "undefined" ? JSON.parse(window.sessionStorage.getItem("login")) : undefined)
    const [loginMessage, setLoginMessage] = useState(undefined)
    const navigate = useNavigate();
    const [params] = useSearchParams();

    const tryLogin = useCallback(({ username, password }) => {
        const login = logins.find(login => login.username === username && login.password === password)
        if (login) {
            setLogin(login)
            console.log({params: params.get("page")})
            navigate(params.get("page") || "/")
            setLoginMessage(undefined)
            return login
        }
        setLoginMessage("Invalid username or password.")
        return false
    }, [navigate, params])

    useEffect(() => {
        window.sessionStorage.setItem("login", JSON.stringify(login))
    }, [login])

    // useEffect(() => {
    //     if (login) {
    //         navigate(params.get("page") || "/")
    //     }
    // }, [])

    return <LoginContext.Provider value={{ login, tryLogin, loginMessage }}>{children}</LoginContext.Provider>
}

export default LoginProvider;