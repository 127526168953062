import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import locations from "../../data/Locations/locations.json";
import fillLocationData from "../../utils/fillLocationData";
import { useNavigate } from "react-router-dom";
import redactText from "../../utils/redactText";

const LocationDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const location = useMemo(() => {
    const l = locations.find((location) => location.id === +id);
    if (l) {
      return fillLocationData(l);
    }

    return undefined;
  }, [id]);

  useEffect(() => {
    if (!location) {
      navigate("/Operations");
    }
  });

  return location ? (
    <div
      style={{
        margin: "25px",
        marginTop: "65px",
        border: "1px dashed",
        padding: "15px",
      }}
    >
      <div>
        <h3>
          {location.city} - {location.state}
        </h3>
        <p>{location.country}</p>
      </div>
      {location?.description ? (
        <div
          style={{
            width: "80%",
            height: "fit-content",
          }}
        >
          <p style={{overflowWrap: "break-word"}}>{redactText(location.description)}</p>
        </div>
      ) : null}
      <div style={{ display: "flex" }}>
        {location.pointsOfInterest.filter((poi) => poi.visible)?.length > 0 ? (
          <div
            style={{
              width: "100%",
              padding: "25px",
              height: "fit-content",
            }}
          >
            <h3>Points of Interest</h3>
            <div style={{ display: "flex" }}>
              {location.pointsOfInterest
                .filter((poi) => poi.visible)
                .map((poi) => (
                  <div
                    style={{
                      display: "flex",
                      border: "1px dashed",
                      padding: "25px",
                      height: "fit-content",
                      flexDirection: "column",
                      maxWidth: "500px",
                      margin: "5px",
                      maxHeight: "500px",
                    }}
                    id={poi.id}
                  >
                    <h3>{redactText(poi.name)}</h3>
                    <p style={{overflowWrap: "break-word"}}>{redactText(poi.description)}</p>
                    {poi?.areas?.filter((area) => area.visible)?.length >
                    0 ? (
                      <div style={{display: "flex" , flexDirection: "column", overflowY: "auto"}}>
                        <h5>Areas</h5>
                        <div style={{display: "flex", flexDirection: "column"}}>
                        {poi.areas
                          .filter((a) => a.visible)
                          .map((area) => (
                            <div
                              id={area.id}
                              style={{
                                border: "1px dashed",
                                padding: "25px",
                                height: "fit-content",
                                display: "flex", flexDirection: "column"
                              }}
                            >
                                <h5>{redactText(area.name)}</h5>
                                <p>{redactText(area.description)}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  ) : null;
};

export default LocationDetail;
