import {useMemo, useState} from "react";
import previousEngagements from "../../data/PreviousEngagements"
import {useNavigate} from "react-router-dom";
import fillEngagementData from "../../utils/fillEngagementData";

const Operations = () => {
    const [search, setSearch] = useState("");

    const navigate = useNavigate();

    const searchResults = useMemo(() => {
        const filledEngagements = previousEngagements.map(engagement => fillEngagementData(engagement))
        if(!search || search.length === 0){
            return filledEngagements;
        }
        const lowerSearch = search.toLowerCase();
        return filledEngagements.filter(engagement => ("" + engagement.year).includes(lowerSearch) || 
        engagement.location.city.toLowerCase().includes(lowerSearch) || 
        engagement.location.state.toLowerCase().includes(lowerSearch) || 
        engagement.location.country.toLowerCase().includes(lowerSearch) || 
        engagement.operatives.some(operative => operative.name.toLowerCase().includes(lowerSearch)) || 
        engagement.relatedEntities.some(relatedEntity => relatedEntity.name.toLowerCase().includes(lowerSearch)))
    }, [search])
    return (
        <div style={{margin: "25px", marginTop: "65px", border: "1px dashed", padding: "15px"}}>
            <div style={{margin: "auto", width: "fit-content"}}>
                <h3 style={{textAlign: "center"}}>Search</h3>
                <input value={search} onChange={(e) => setSearch(e.target.value)} />
            </div>
            <div style={{display: "flex", flexFlow:"row wrap", justifyContent: "center", marginTop: "20px"}}>
                    {searchResults.map(engagement => (
                        <div onClick={() => navigate(`/Operation/${engagement.id}`, {reloadDocument: true})} className="card" key={engagement.id} style={{cursor: "pointer", display: "flex", flexDirection: "column", padding: "10px", paddingLeft: "25px", paddingRight: "25px", border: "dashed 1px"}}>
                            <h3>{`${engagement.location.city} - ${engagement.location.state}`}</h3>
                            <p>{engagement.year}</p>
                        </div>
                    ))}
                </div>
        </div>
    )
}

export default Operations;